table {
    border-collapse: collapse;
}
table td {
    padding: 6px 12px;
}
table thead tr th {
    padding: 6px 12px;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #dddfe1;
}
table tbody td {
    color: #636363;
    border: 1px solid #dddfe1;
    font-size: 15px;
}
table tbody tr {
    background-color: #f9fafb;
}
table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

